<template>
    <main>
        <div class="hero" style="background-image: linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url('images/cb_inner_pages_default_bg-min.jpg');">
           <div class="container container--xs text-center">
                <h1>Gracias por afiliar tu tienda, en breve le notificaremos por correo electrónico su aprobación</h1>
                <!-- <p>Subtítulo sumilla o texto editable</p> -->
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Afilia tu tienda</span></p>
                <h2><a href="#"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Afiliación</h2>
                <div class="py-lg pt-0">                    
                    <p class="form__success">Gracias por afiliar tu tienda, en breve le notificaremos por correo electrónico su aprobación</p>                    
                </div>
                
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: "AffiliateSuccess",
}
</script>